import React, {useRef} from 'react';
import {useDrop, useDrag} from 'react-dnd';
import {ItemTypes} from '@/types/ItemTypes';

interface FormDraggableComponentProps {
  id: string;
  index: number;
  Component: React.ElementType | null;
  moveComponent: (dragIndex: number, hoverIndex: number) => void;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const TasksDraggableComponent: React.FC<FormDraggableComponentProps> = ({
  id,
  index,
  Component,
  moveComponent,
  onEdit,
  onCopy,
  onDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{handlerId}, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: {id: number; index: number}, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY! < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY! > hoverMiddleY) {
        return;
      }

      moveComponent(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.COMPONENT,
    item: {id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{opacity: isDragging ? 0.5 : 1}}
      data-handler-id={handlerId}
    >
      {Component ? (
        <Component onEdit={onEdit} onCopy={onCopy} onDelete={onDelete} />
      ) : (
        <div>Component não encontrado</div>
      )}
    </div>
  );
};

export default TasksDraggableComponent;
