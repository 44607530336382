import React, {useState} from 'react';
import {Box, Typography, Button} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/pro-solid-svg-icons';
const rows = [
  {
    id: 1,
    fileName: 'Análise_de_Desempenho_Financeiro.pdf',
  },
  {
    id: 2,
    fileName: 'Diretrizes_de_Comunicação_Organizacional.docx',
  },
  {
    id: 3,
    fileName: 'Plano_de_Treinamento_e_Capacitação.xlsx',
  },
  {
    id: 4,
    fileName: 'Resumo_Executivo_de_Inovação_Tecnológica.pptx',
  },
];

const Attachments: React.FC = () => {
  const [activeSection, setActiveSection] = useState('Anexos das respostas');
  const options = ['Anexos das respostas', 'Anexos das discussões'];

  return (
    <Box display="flex" flexDirection="row" gap="10px">
      <Box width="100%" marginRight="10px">
        <Typography fontSize="16px" fontWeight="700" color="#001B37">
          Todos anexos
        </Typography>
        <Typography
          fontSize="12px"
          fontWeight="600"
          color="#001B37"
          marginBottom="27px"
        >
          Todos os arquivos anexados no cartão e também das respostas colhidas
        </Typography>

        <Box display="flex" flexDirection="row" gap="24px" marginBottom="20px">
          {options.map((option) => (
            <Typography
              key={option}
              onClick={() => setActiveSection(option)}
              sx={{
                cursor: 'pointer',
                color: activeSection === option ? '#0059B2' : '#4D5156',
                fontWeight: activeSection === option ? '700' : '600',
                fontSize: '10px',
                textDecoration:
                  activeSection === option ? 'underline' : undefined,
              }}
            >
              {option}
            </Typography>
          ))}
        </Box>

        {rows.map((row, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="14px"
            border="1px solid rgba(0, 62, 124, 0.30)"
            borderRadius="5px"
            marginBottom="10px"
          >
            <Typography
              fontSize="10px"
              fontWeight="500"
              color="#001B37"
              sx={{width: '150px', textDecoration: 'underline'}}
            >
              {row.fileName}
            </Typography>
            <FontAwesomeIcon icon={faUpload} color="#003E7C" />
          </Box>
        ))}
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-end"
          marginTop="30px"
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: '#0897CB',
              border: 'none',
              color: '#ffffff',
              fontSize: '12px',
              padding: '5px 8px',
              ':hover': {
                backgroundColor: '#00C1EB',
                border: 'none',
              },
            }}
            startIcon={
              <FontAwesomeIcon icon={faUpload} color="#ffffff" width="12px" />
            }
          >
            Baixar tudo
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Attachments;
