import React, {useState, useCallback} from 'react';
import {useDrop} from 'react-dnd';
import {Box, Typography} from '@mui/material';
import {ItemTypes} from '@/types/ItemTypes';
import {TasksDraggableComponent, FormPlaceholder} from '@/components/index';
interface ComponentData {
  id: string;
  title?: string;
  Component: React.ElementType;
}

interface DragItem {
  type: React.ElementType;
  label: string;
}

const EmptyForm: React.FC = () => {
  const [components, setComponents] = useState<ComponentData[]>([]);
  const [currentComponentId, setCurrentComponentId] = useState<string | null>(
    null,
  );
  const [currentTitle, setCurrentTitle] = useState<string>('');
  const [isCopy, setIsCopy] = useState(false);
  const [placeholderIndex, setPlaceholderIndex] = useState<number | null>(null);
  const generateId = (): string =>
    `id_${Math.random().toString(36).substr(2, 9)}`;

  const handleSelectComponent = useCallback(
    (component: React.ElementType, label: string) => {
      setComponents((prevComponents) => [
        ...prevComponents,
        {
          id: generateId(),
          Component: component,
          title: label,
        },
      ]);
    },
    [],
  );
  const handleCopy = (id: string) => {
    const component = components.find((c) => c.id === id);
    setCurrentComponentId(id);
    setCurrentTitle(component ? component.title || '' : '');
    setIsCopy(true);
  };

  const handleDelete = (id: string) => {
    setComponents(components.filter((c) => c.id !== id));
  };
  const handleEdit = (id: string) => {
    const component = components.find((c) => c.id === id);
    if (component) {
      setCurrentComponentId(id);
      setCurrentTitle(component.title || '');
      setIsCopy(false);
    }
  };
  const [{isOver}, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    hover: (item: DragItem, monitor) => {
      const hoverIndex = components.length;
      setPlaceholderIndex(hoverIndex);
    },
    drop: (item: DragItem, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      handleSelectComponent(item.type, item.label);
      setPlaceholderIndex(null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const moveComponent = (dragIndex: number, hoverIndex: number) => {
    const dragComponent = components[dragIndex];
    const updatedComponents = [...components];
    updatedComponents.splice(dragIndex, 1);
    updatedComponents.splice(hoverIndex, 0, dragComponent);
    setComponents(updatedComponents);
    setPlaceholderIndex(null);
  };
  return (
    <Box width="100%">
      <Typography
        fontSize="16px"
        fontWeight="700"
        color="#001B37"
        marginBottom="10px"
      >
        Criando as tarefas
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="600"
        color="#001B37"
        marginBottom="10px"
      >
        Arraste e solte os campos obrigatórios que devem ser preenchidos antes
        de mover o cartão para a próxima etapa.
      </Typography>
      <Box
        ref={drop}
        component="div"
        marginTop="40px"
        marginRight="20px"
        sx={{
          backgroundColor: isOver ? '#e0f7fa' : '#FFFFFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          padding: '20px',
          borderRadius: '5px',
          border: ' 2px dashed var(--Azuis-claros-Azul-boto-hover, #00C1EB)',
          flexDirection: 'column',
        }}
      >
        {components.length > 0 ? (
          components.map((componentData, index) => (
            <Box key={index} sx={{marginBottom: '20px', width: '100%'}}>
              <TasksDraggableComponent
                id={componentData.id}
                index={index}
                Component={componentData.Component}
                moveComponent={moveComponent}
                onEdit={() => handleEdit(componentData.id)}
                onCopy={() => handleCopy(componentData.id)}
                onDelete={() => handleDelete(componentData.id)}
              />
            </Box>
          ))
        ) : (
          <Typography fontSize="14px" color="#999999" textAlign="center">
            Arraste e solte componentes aqui
          </Typography>
        )}
        {isOver && placeholderIndex === components.length && (
          <FormPlaceholder />
        )}
      </Box>
    </Box>
  );
};

export default EmptyForm;
