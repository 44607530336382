import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {
  ShortText,
  LongText,
  Number,
  MultipleChoice,
  SelectionBox,
  DraggableComponent,
  Paragraph,
  DatePicker,
} from '@/components/index';
import {
  faBallotCheck,
  faBallot,
  faHashtag,
  faTextSize,
  faText,
} from '@fortawesome/pro-solid-svg-icons';

interface FormItem {
  id: string;
  label: string;
  emoji: any;
  component: any;
}

interface SubmenuProps {
  onSelect?: (formItem: FormItem) => void;
}

const MandatoryTasksMenu: React.FC<SubmenuProps> = ({onSelect}) => {
  const formItems: FormItem[] = [
    {
      id: '1',
      label: 'Caixa de seleção',
      emoji: faBallotCheck,
      component: SelectionBox,
    },

    {
      id: '2',
      label: 'Múltipla escolha',
      emoji: faBallot,
      component: MultipleChoice,
    },
    {
      id: '3',
      label: 'Número',
      emoji: faHashtag,
      component: Number,
    },

    {
      id: '4',
      label: 'Texto curto',
      emoji: faTextSize,
      component: ShortText,
    },
    {
      id: '5',
      label: 'Texto longo',
      emoji: faText,
      component: LongText,
    },
  ];

  const handleSelect = (item: FormItem) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <Box className="submenu-board" sx={{backgroundColor: '#FFFFFF'}}>
      <Box display="flex" flexDirection="column" gap="20px">
        {formItems.map((item) => (
          <DraggableComponent
            key={item.id}
            id={item.id}
            label={item.label}
            emoji={item.emoji}
            component={item.component}
            onSelect={() => handleSelect(item)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MandatoryTasksMenu;
