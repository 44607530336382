import {Box, Stack, InputLabel, TextField} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {ToastSuccess, ToastError} from '@/components/shared';
import React from 'react';
import {SectionHeader, Checklist} from '@/components/index';
import {useNavigate} from 'react-router';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import {WorkflowStepCreateUpdate} from '@/services/workflow-step/models/workflow-step-create-update.dto.tsx';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {CardShowcase, FirstAccessFooter} from '@/components/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';
interface NewTeamProps {
  onSubmit?: (step?: WorkflowStepCreateUpdate) => Promise<void>;
  data?: WorkflowStepDto;
}

const StepScheme = Yup.object().shape({
  title: Yup.string().required(),
  allowCreateCard: Yup.boolean().required(),
  icon: Yup.object().required().shape({
    label: Yup.object(),
    value: Yup.string().required(),
  }),
});

const NewTeam: React.FC<NewTeamProps> = ({onSubmit, data}) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await onSubmit?.();
      ToastSuccess({
        title: 'Sucesso!',
        text: `Etapa ${data?.id ? 'editada' : 'cadastrada'} com sucesso!`,
      });
    } catch (error) {
      ToastError({
        title: 'Erro!',
        text: `Erro ao ${data?.id ? 'editar' : 'cadastrar'} uma etapa. Contate o administrador`,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Box
        flex="1"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Formik
          enableReinitialize
          initialValues={{
            title: data?.title || '',
            allowCreateCard: data?.allowCreateCard ?? true,
            icon: data?.icon
              ? WorkflowIconList.find((i) => i.value === data.icon)
              : WorkflowIconList[0],
          }}
          validationSchema={StepScheme}
          onSubmit={async (values) => {
            let payload: WorkflowStepCreateUpdate = {
              title: values.title,
              allowCreateCard: values.allowCreateCard,
              icon: values.icon.value,
            };

            try {
              await onSubmit?.(payload);
            } catch (error) {
              ToastError({
                title: 'Erro!',
                text: `Erro ao ${
                  data?.id ? 'editar' : 'cadastrar'
                } uma etapa. Contate o administrador`,
              });
              console.error(error);
            }
          }}
        >
          {({setFieldValue, values, setFieldTouched}) => (
            <Box marginRight="40px" width="100%">
              <Stack mb="28px" gap="30px">
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SectionHeader
                    title="Criar equipe"
                    subtitle="Escolha um ícone e um nome para sua equipe"
                  />

                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => navigate('/minhas-equipes')}
                    style={{
                      cursor: 'pointer',
                      height: '24px',
                      color: 'rgba(0, 39, 78, 1)',
                    }}
                  />
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="start"
                  gap="30px"
                >
                  <CardShowcase title={values.title} />
                  <Box display="flex" gap="20px" flexDirection="column">
                    <Box>
                      <InputLabel
                        htmlFor="filled-required"
                        sx={{
                          color: '#00152A',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}
                        required
                      >
                        Nome da equipe
                      </InputLabel>
                      <TextField
                        required
                        id="filled-required"
                        placeholder="Digite um título"
                        variant="outlined"
                        InputProps={{
                          style: {
                            fontSize: '16px',
                            color: '#4D5156',
                            fontWeight: '500',
                          },
                        }}
                        sx={{
                          minWidth: '350px',
                          marginBottom: '40px',
                        }}
                        value={values.title}
                        onChange={(e) => setFieldValue('title', e.target.value)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <FirstAccessFooter
                leftButtonRoute="/minhas-equipes"
                leftButtonText="Voltar"
                rightButtonRoute="/minhas-equipes"
                rightButtonText="Salvar"
              />
            </Box>
          )}
        </Formik>
        <Checklist active="/minhas-equipes" />
      </Box>
    </>
  );
};

export default NewTeam;
