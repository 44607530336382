import React, {useState} from 'react';
import {Box, Typography, Button, Checkbox, TextField} from '@mui/material';
import {faPlus, faSpider} from '@fortawesome/pro-solid-svg-icons';
import {faSpiderWeb} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';

const NewAction: React.FC = () => {
  const [newAction, setNewAction] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      setAnswers([...answers, {title: inputValue, status: false}]);
      setInputValue('');
      setNewAction(false);
    }
  };
  return (
    <Box flex="0 0 30%" maxWidth="30%">
      <Box
        display="flex"
        flexDirection="row"
        gap="27px"
        alignItems="center"
        marginBottom="5px"
      >
        <Typography fontSize="16px" fontWeight="700">
          Ações extras
        </Typography>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: '#0897CB',
            border: 'none',
            color: '#ffffff',
            fontSize: '12px',
            padding: '5px 8px',
            ':hover': {
              backgroundColor: '#00C1EB',
              border: 'none',
            },
          }}
          startIcon={<FontAwesomeIcon icon={faPlus} color="#ffffff" />}
          onClick={() => setNewAction(true)}
        >
          adicionar ação extra
        </Button>
      </Box>
      <Typography>
        Divida o trabalho com tarefas menores e gerencie melhor seu tempo
      </Typography>
      {newAction && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop="27px"
        >
          <Checkbox />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Adicione aqui a ação a ser realizada"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </Box>
      )}
      {answers
        .slice()
        .reverse()
        .map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Checkbox
              checked={item.status}
              onChange={(e) => {
                const updatedAnswers = [...answers];
                updatedAnswers[answers.length - 1 - index].status =
                  e.target.checked;
                setAnswers(updatedAnswers);
              }}
            />
            <Typography variant="body1">
              {item.title} - {item.status ? 'Completo' : 'Pendente'}
            </Typography>
          </Box>
        ))}

      {!newAction && answers.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="70px"
        >
          <FontAwesomeIcon
            icon={faSpiderWeb}
            color="#4D5156"
            opacity="0.3"
            fontSize="77px"
          />
          <FontAwesomeIcon
            icon={faSpider}
            color="#4D5156"
            opacity="0.3"
            fontSize="27px"
          />
          <Typography
            textAlign="center"
            color="#4D5156"
            marginTop="22px"
            fontSize="11px"
            fontWeight="500"
          >
            Nenhuma ação extra ainda. Crie novas tarefas para facilitar seu
            progresso!
          </Typography>
        </Box>
      )}
      <Button
        variant="outlined"
        sx={{
          border: 'none',
          color: '#003E7C',
          fontSize: '13px',
          padding: '5px 8px',
          ':hover': {
            backgroundColor: '#00C1EB',
            border: 'none',
          },
        }}
        endIcon={
          <FontAwesomeIcon icon={faArrowRight} color="#003E7C" width="12px" />
        }
      >
        Mover cartão para
      </Button>
    </Box>
  );
};

export default NewAction;
