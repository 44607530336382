import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {SectionHeader} from '@/components/index';

type SettingsProps = {
  initialSection?: string;
};

const MenuSettings: React.FC<SettingsProps> = ({initialSection = 'Perfil'}) => {
  const [activeSection, setActiveSection] = useState(initialSection);
  const options = ['Perfil', 'Empresa'];

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
  };

  return (
    <>
      <SectionHeader title="Configurações" />

      <Box display="flex" flexDirection="row" gap="24px" mt={2}>
        {options.map((option) => (
          <Typography
            key={option}
            onClick={() => setActiveSection(option)}
            sx={{
              cursor: 'pointer',
              color:
                activeSection === option ? '#00152A' : 'rgba(0, 21, 42, 0.5)',
              fontWeight: activeSection === option ? '700' : '600',
              fontSize: '18px',
            }}
          >
            {option}
          </Typography>
        ))}
      </Box>

      <Box mt={3}>
        {activeSection === 'Perfil' ? (
          <Typography>Conteúdo de Configurações do Perfil</Typography>
        ) : (
          <Typography>Conteúdo de Configurações da Empresa</Typography>
        )}
      </Box>
    </>
  );
};

export default MenuSettings;
