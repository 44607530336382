import React, {useState} from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {Comment} from '@/components/index';
const comments = [
  {
    id: 2,
    person: 'Bruno Anselmo',
    comment:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam ipsum dolor sit amet consectetur adipiscing',
    date: '05 SET',
  },
  {
    id: 3,
    person: 'Leandro Batagin',
    comment:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam ipsum dolor sit amet consectetur adipiscing',
    date: '05 SET',
  },
];

const Comments: React.FC = () => {
  return (
    <Box>
      <Typography fontSize="16px" fontWeight="700" color="#001B37">
        Comentários
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="600"
        color="#001B37"
        marginBottom="37px"
      >
        Deixe suas ideias, sugestões ou comentários
      </Typography>

      {comments.map((comment, index) => (
        <Box key={index}>
          <Comment comment={comment.comment} person={comment.person} />
        </Box>
      ))}
    </Box>
  );
};

export default Comments;
