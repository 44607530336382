import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {useDrag} from 'react-dnd';
import {ItemTypes} from '@/types/ItemTypes';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera} from '@fortawesome/pro-solid-svg-icons';

interface DraggableComponentProps {
  id: string;
  label: string;
  emoji: any;
  component: React.ElementType;
  onSelect: (component: React.ElementType) => void;
  colSpan?: number;
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  id,
  label,
  emoji,
  component,
  onSelect,
  colSpan,
}) => {
  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.COMPONENT,
    item: {type: component, label, colSpan},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={drag}
      display="flex"
      flexDirection="row"
      gap="8px"
      alignItems="center"
      sx={{
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {label !== 'Adicionar seção' ? (
        <>
          <FontAwesomeIcon
            icon={emoji}
            style={{
              cursor: 'pointer',
              height: '14px',
              color: '#003E7C',
            }}
          />
          <Typography fontSize="14px" color="#383838">
            {label}
          </Typography>
        </>
      ) : (
        <Box gap="10px" display="flex" flexDirection="column">
          <Divider />
          <Box display="flex" flexDirection="row" gap="8px">
            <EmojiIcon emoji={Emoji.Mais} width="16px" />
            <Typography fontSize="11px" fontWeight="600">
              Adicionar seção
            </Typography>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default DraggableComponent;
