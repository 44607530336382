import React from 'react';
import {Box} from '@mui/material';
import Rocket from '@/assets/svg/rocket.svg';

interface FormPlaceholderProps {
  style?: React.CSSProperties;
}

const FormPlaceholder: React.FC<FormPlaceholderProps> = ({style}) => {
  return (
    <>
      <Box
        flex="1"
        width="100%"
        height="100px"
        justifyContent="center"
        alignItems="center"
        border="2px solid rgba(0, 193, 235, 0.66)"
        borderRadius="5px"
        display="flex"
      >
        <img
          style={{display: 'block', width: '44px'}}
          src={Rocket}
          alt="Foguete"
        />
      </Box>
    </>
  );
};

export default FormPlaceholder;
