import React, {useState} from 'react';
import {Modal, Box, Typography, Divider} from '@mui/material';
import responsibleImage from '@/assets/images/Responsible.jpg';
import {WorkflowStepCardDto} from '@/models/workflow-step-card.dto.ts';
import {WorkflowKanbanCardValue} from '@/pages/workflow/kanban/components/workflowKanbanCardValue';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faComments,
  faSquareCheck,
  faLink,
  faHistory,
  faCalendarDays,
} from '@fortawesome/pro-solid-svg-icons';
import {
  NewAction,
  History,
  Attachments,
  Tasks,
  Comments,
} from '@/sections/index';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {ModalContainer} from '@/components/index';
interface OpenCardModal {
  card?: WorkflowStepCardDto | null;
  isOpen: boolean;
  onClose: () => void;
}

const menuItens = [
  {
    name: 'Comentários',
    icon: faComments,
    component: <Comments />,
  },
  {
    name: 'Tarefas',
    icon: faSquareCheck,
    component: <Tasks />,
  },
  {
    name: 'Anexos',
    icon: faLink,
    component: <Attachments />,
  },
  {
    name: 'Históricos',
    icon: faHistory,
    component: <History />,
  },
];

const OpenCardModal: React.FC<OpenCardModal> = ({isOpen, onClose, card}) => {
  const [activeMenu, setActiveMenu] = useState<string>(menuItens[0].name);

  if (!card) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const cardValues = card.values
    .filter((v) => v.workflowFormInput.showInCard)
    .sort((a, b) => a.workflowFormInput.order - b.workflowFormInput.order);
  const headerValue = card.values
    .filter((v) => v.workflowFormInput.header)
    .pop();

  const activeComponent = menuItens.find(
    (item) => item.name === activeMenu,
  )?.component;

  return (
    <>
      <ModalContainer isOpen={isOpen} onClose={handleClose}>
        {/* Header do modal */}
        <Box display="flex" flexDirection="row" gap="10px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            <Box
              width="30px"
              height="30px"
              borderRadius="50%"
              border="1px solid rgba(0, 39, 78, 0.30)"
              style={{
                backgroundImage: `url(${responsibleImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Typography color="#001B37" fontSize="14px" fontWeight="600">
              Leandro Batagin
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            color="#B7E6FF"
            style={{
              height: '13px',
              alignSelf: 'center',
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            <FontAwesomeIcon
              icon={faCalendarDays}
              color="#003E7C"
              fontSize="12px"
            />
            <Typography color="#003E7C">05 SET</Typography>
          </Box>
        </Box>

        {/* Menu de opções */}
        <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
          <EmojiIcon emoji={Emoji.Warning} width="17px" />
          <Typography fontSize="10px" fontWeight="600" color="#003E7C">
            Em andamento
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            color="#B7E6FF"
            style={{
              height: '13px',
              alignSelf: 'center',
            }}
          />
          {menuItens.map((item, index) => (
            <Box key={index} display="flex" flexDirection="row">
              <Box
                display="flex"
                marginRight="10px"
                flexDirection="row"
                padding="5px 10px"
                alignItems="center"
                gap="5px"
                onClick={() => setActiveMenu(item.name)}
                sx={{
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#D6F1FF',
                    borderRadius: '5px',
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{
                    height: '14px',
                    color: '#003E7C',
                  }}
                />
                <Typography color="#003E7C">{item.name}</Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                color="#B7E6FF"
                style={{
                  height: '13px',
                  alignSelf: 'center',
                }}
              />
            </Box>
          ))}
        </Box>

        {/* Conteúdo do modal */}
        <Box display="flex" flexDirection="row" height="100%">
          <Box flex="0 0 20%" maxWidth="20%">
            <Typography fontSize="16px" fontWeight="700" color="#001B37">
              Meu primeiro formulário
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="600"
              color="#001B37"
              marginBottom="20px"
            >
              Respostas colhidas
            </Typography>
            {cardValues.map((value) => (
              <WorkflowKanbanCardValue cardValue={value} />
            ))}
          </Box>
          <Divider
            flexItem
            orientation="vertical"
            color="#B7E6FF"
            sx={{marginRight: '20px'}}
          />

          <Box flex="1" maxWidth="50%">
            {activeComponent}
          </Box>
          <Divider
            flexItem
            orientation="vertical"
            color="#B7E6FF"
            sx={{marginRight: '20px'}}
          />
          <NewAction />
        </Box>
      </ModalContainer>
    </>
  );
};

export default OpenCardModal;
