import React from 'react';
import {
  Modal,
  Fade,
  IconButton,
  Box,
  Button,
  Checkbox,
  TextField,
  Divider,
  InputLabel,
  Typography,
} from '@mui/material';
import {useWindowWidth} from '@/utils/useWindowWidth';
import SelectWorkflowIcon from '@/components/selectWorkflowIcon';
import {EmptyMandatoryTasks} from '@/sections/index';
import {MandatoryTasksMenu, EmptyForm} from '@/components/index';
import * as Yup from 'yup';
import {ToastSuccess, ToastError, EmojiIcon} from '@/components/shared';
import {WorkflowStepCreateUpdate} from '@/services/workflow-step/models/workflow-step-create-update.dto.tsx';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {Formik} from 'formik';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import KanbanShowcase from '@/components/kanbanShowcase/index';

interface StepConfiguration {
  onSubmit?: (step?: WorkflowStepCreateUpdate) => Promise<void>;
  data?: WorkflowStepDto;
}

const StepScheme = Yup.object().shape({
  title: Yup.string().required(),
  allowCreateCard: Yup.boolean().required(),
  icon: Yup.object().required().shape({
    label: Yup.object(),
    value: Yup.string().required(),
  }),
});

const StepConfiguration: React.FC<StepConfiguration> = ({onSubmit, data}) => {
  const handleSubmit = async () => {
    try {
      await onSubmit?.();
      ToastSuccess({
        title: 'Sucesso!',
        text: `Etapa ${data?.id ? 'editada' : 'cadastrada'} com sucesso!`,
      });
    } catch (error) {
      ToastError({
        title: 'Erro!',
        text: `Erro ao ${data?.id ? 'editar' : 'cadastrar'} uma etapa. Contate o administrador`,
      });
      console.error(error);
    }
  };
  const width = useWindowWidth();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          title: data?.title || '',
          allowCreateCard: data?.allowCreateCard ?? true,
          icon: data?.icon
            ? WorkflowIconList.find((i) => i.value === data.icon)
            : WorkflowIconList[0],
        }}
        validationSchema={StepScheme}
        onSubmit={async (values) => {
          let payload: WorkflowStepCreateUpdate = {
            title: values.title,
            allowCreateCard: values.allowCreateCard,
            icon: values.icon.value,
          };

          try {
            await onSubmit?.(payload);
          } catch (error) {
            ToastError({
              title: 'Erro!',
              text: `Erro ao ${
                data?.id ? 'editar' : 'cadastrar'
              } uma etapa. Contate o administrador`,
            });
            console.error(error);
          }
        }}
      >
        {({setFieldValue, values, setFieldTouched}) => (
          <Box
            display="flex"
            flexDirection="row"
            height="100%"
            width="100%"
            justifyContent="space-between"
          >
            <Box flex="0 0 50%">
              <Typography fontSize="16px" fontWeight="700" color="#001B37">
                Configurar etapa
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="600"
                color="#001B37"
                marginBottom="20px"
              >
                Personalize as configurações da etapa
              </Typography>

              <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                <Box
                  display="flex"
                  flexDirection={width > 768 ? 'row' : 'column'}
                  alignItems={width > 768 ? 'center' : undefined}
                  gap="16px"
                >
                  <Box>
                    <Typography variant="body2">Nome da etapa</Typography>
                    <TextField
                      required
                      id="filled-required"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                      placeholder="Digite um título"
                      sx={{
                        maxWidth: '266px',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="body2">Escolha seu ícone</Typography>
                    <SelectWorkflowIcon
                      id="workflow-select-icon"
                      placeholder={''}
                      className="workflow-select-icon"
                      value={values.icon}
                      onChange={async (v) => {
                        await setFieldValue('icon', v);
                        await setFieldTouched('icon');
                      }}
                      loading={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox></Checkbox>
                  <Typography variant="body2">
                    Criar cards nessa etapa
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">É uma etapa de:</Typography>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '40px',
                    right: '40px',
                  }}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      backgroundColor: '#0897CB',
                    }}
                  >
                    Concluir
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              color="#B7E6FF"
              sx={{marginRight: '20px'}}
            />
            <Box flex="0 0 50%">
              <Typography fontSize="16px" fontWeight="700" color="#001B37">
                Preview
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="600"
                color="#001B37"
                marginBottom="20px"
              >
                Dê uma olhada em como sua etapa está
              </Typography>
              <KanbanShowcase emoji={values.icon.value} title={values.title} />
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default StepConfiguration;
