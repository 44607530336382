import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {EmptyMandatoryTasks} from '@/sections/index';
import {MandatoryTasksMenu, EmptyForm} from '@/components/index';

const MandatoryTasks: React.FC = () => {
  return (
    <>
      <Box display="flex" flexDirection="row" height="100%" width="100%">
        <Box flex="0 0 20%" maxWidth="20%">
          <Typography fontSize="16px" fontWeight="700" color="#001B37">
            Tarefas obrigatórias
          </Typography>
          <Typography
            fontSize="12px"
            fontWeight="600"
            color="#001B37"
            marginBottom="20px"
          >
            Arraste os campos de seu interesse
          </Typography>
          <MandatoryTasksMenu />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          color="#B7E6FF"
          sx={{marginRight: '20px'}}
        />
        <EmptyForm />
        <Divider
          flexItem
          orientation="vertical"
          color="#B7E6FF"
          sx={{marginRight: '20px'}}
        />
        <EmptyMandatoryTasks />
      </Box>
    </>
  );
};

export default MandatoryTasks;
