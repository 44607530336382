import React, {ReactNode} from 'react';
import {Box, Typography, Button} from '@mui/material';
import {useLocation} from 'react-router-dom';
import responsibleImage from '@/assets/images/Responsible.jpg';

interface CommentProps {
  comment: string;
  person: string;
}

const Comment: React.FC<CommentProps> = ({comment, person}) => {
  return (
    <Box maxWidth="500px">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="15px"
        marginBottom="23px"
      >
        <Box
          width="30px"
          height="30px"
          borderRadius="50%"
          border="1px solid rgba(0, 39, 78, 0.30)"
          style={{
            backgroundImage: `url(${responsibleImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Typography color="#00274E" fontSize="13px" fontWeight="600">
          {person}
        </Typography>
      </Box>
      <Typography color="#00274E" fontSize="11px" fontWeight="500">
        {comment}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          variant="text"
          sx={{
            fontSize: '9px',
            color: '#001B37',
            fontWeight: '600',
            padding: '0px',
            margin: '0px',
          }}
        >
          Editar
        </Button>
        <Button
          variant="text"
          sx={{fontSize: '9px', color: '#001B37', fontWeight: '600'}}
        >
          Deletar
        </Button>
      </Box>
    </Box>
  );
};

export default Comment;
