import {Typography, Box} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import './index.css';

const WorkflowKanbanHeader = ({icon, text, counter}) => {
  return (
    <Box className="workflow-board-card header">
      <EmojiIcon emoji={icon} width="18px" />
      <Typography fontSize="16px" fontWeight="500">
        {text}
      </Typography>
      <Typography fontSize="14px" className={'counter'}>
        {counter}
      </Typography>
    </Box>
  );
};

export default WorkflowKanbanHeader;
