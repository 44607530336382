import React from 'react';
import {Box, Typography, Button} from '@mui/material';
import FirstAccessSvg from '@/assets/svg/FirstAcces.svg';
import Icon from '@/components/shared/icon';
import {useNavigate} from 'react-router-dom';

const menuItens = [
  {
    id: '1',
    title: 'Primeiramente crie sua empresa',
  },
  {
    id: '2',
    title: 'Com sua empresa pronta, agora é hora de criar suas equipes',
  },
  {
    id: '3',
    title:
      'Com as equipes criadas, convide os membros e comece a criar seus fluxos',
  },
];

const FirstAccessScreen: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="main"
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-around"
      gap="20px"
    >
      <>
        <Box component="section">
          <Typography component="h1" variant="h1" marginBottom="10px">
            Conheça o E-fluxo
          </Typography>
          <Typography
            component="h2"
            variant="subtitle1"
            marginBottom="25px"
            sx={{wordBreak: 'break-word', maxWidth: '450px'}}
          >
            Organize os processos da sua empresa com apenas alguns cliques.
          </Typography>

          {menuItens.map((item) => (
            <Box
              key={item.id}
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="15px"
              marginBottom="24px"
            >
              <Icon
                faIcon={{prefix: 'fas', iconName: 'check-circle'}}
                width={24}
                height={24}
                color="#00B050"
              />
              <Typography
                component="p"
                variant="body2"
                color="#535353"
                sx={{wordBreak: 'break-word', maxWidth: '350px'}}
              >
                {item.title}
              </Typography>
            </Box>
          ))}
          <Button
            variant="contained"
            sx={{padding: '10px 58px', fontSize: '18px', marginTop: '100px'}}
            onClick={() => navigate('/cadastrar-empresa')}
          >
            Criar empresa
          </Button>
        </Box>
      </>
      <img src={FirstAccessSvg} alt="Primeiro acesso" />
    </Box>
  );
};

export default FirstAccessScreen;
