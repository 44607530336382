import {Box, Stack, InputLabel, TextField} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {ToastSuccess, ToastError} from '@/components/shared';
import React from 'react';
import {SectionHeader, FirstAccessFooter} from '@/components/index';
import {useNavigate} from 'react-router';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import {WorkflowStepCreateUpdate} from '@/services/workflow-step/models/workflow-step-create-update.dto.tsx';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {CardShowcase, Select} from '@/components/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';

interface NewTeamProps {
  onSubmit?: (step?: WorkflowStepCreateUpdate) => Promise<void>;
  data?: WorkflowStepDto;
}

const StepScheme = Yup.object().shape({
  title: Yup.string().required(),
  allowCreateCard: Yup.boolean().required(),
  icon: Yup.object().required().shape({
    label: Yup.object(),
    value: Yup.string().required(),
  }),
});

const items = [
  {label: 'Importação', icon: 'faCheckCircle', color: 'rgba(0, 176, 80, 1)'},
  {label: 'Suporte', icon: 'faClock', color: 'rgba(252, 151, 0, 1)'},
  {label: 'Marketing', icon: 'faTimesCircle', color: 'rgba(255, 56, 87, 1)'},
];

const NewWorkflow: React.FC<NewTeamProps> = ({onSubmit, data}) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await onSubmit?.();
      ToastSuccess({
        title: 'Sucesso!',
        text: `Etapa ${data?.id ? 'editada' : 'cadastrada'} com sucesso!`,
      });
    } catch (error) {
      ToastError({
        title: 'Erro!',
        text: `Erro ao ${data?.id ? 'editar' : 'cadastrar'} uma etapa. Contate o administrador`,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          title: data?.title || '',
          allowCreateCard: data?.allowCreateCard ?? true,
          icon: data?.icon
            ? WorkflowIconList.find((i) => i.value === data.icon)
            : WorkflowIconList[0],
        }}
        validationSchema={StepScheme}
        onSubmit={async (values) => {
          let payload: WorkflowStepCreateUpdate = {
            title: values.title,
            allowCreateCard: values.allowCreateCard,
            icon: values.icon.value,
          };

          try {
            await onSubmit?.(payload);
          } catch (error) {
            ToastError({
              title: 'Erro!',
              text: `Erro ao ${
                data?.id ? 'editar' : 'cadastrar'
              } uma etapa. Contate o administrador`,
            });
            console.error(error);
          }
        }}
      >
        {({setFieldValue, values, setFieldTouched}) => (
          <Box marginRight="40px">
            <Stack mb="28px" gap="30px">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SectionHeader
                  title="Criar fluxo"
                  subtitle="Escolha um nome, defina a equipe e escolha um ícone para seu fluxo"
                />

                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => navigate('/meus-fluxos')}
                  style={{
                    cursor: 'pointer',
                    height: '24px',
                    color: 'rgba(0, 39, 78, 1)',
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="row" gap="30px">
                <CardShowcase title={values.title} />
                <Box display="flex" flexDirection="column">
                  <Box marginBottom="24px">
                    <InputLabel
                      htmlFor="filled-required"
                      sx={{
                        color: '#00152A',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                      required
                    >
                      Nome do fluxo
                    </InputLabel>
                    <TextField
                      required
                      id="filled-required"
                      placeholder="Digite um título"
                      variant="outlined"
                      InputProps={{
                        style: {
                          fontSize: '14px',
                          color: '#4D5156',
                          fontWeight: '500',
                        },
                      }}
                      sx={{
                        minWidth: '350px',
                        maxHeight: '52px',
                      }}
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                  </Box>
                  <Select title="Defina equipe" items={items} />
                </Box>
              </Box>
            </Stack>
            <FirstAccessFooter
              leftButtonRoute="/meus-fluxos"
              leftButtonText="Voltar"
              rightButtonRoute="/meus-fluxos"
              rightButtonText="Salvar"
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

export default NewWorkflow;
