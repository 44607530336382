import React from 'react';
import {Box, Typography} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import './index.css';

interface KanbanShowcaseProps {
  title: string;
  emoji: string;
}

const KanbanShowcase: React.FC<KanbanShowcaseProps> = ({title, emoji}) => {
  return (
    <Box maxWidth="400px">
      <Box className="container">
        {' '}
        <EmojiIcon emoji={emoji}></EmojiIcon>
        <Typography>{title}</Typography>
      </Box>
    </Box>
  );
};

export default KanbanShowcase;
