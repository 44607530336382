import React from 'react';
import {Box} from '@mui/material';
import Rocket from '@/assets/svg/rocket.svg?react';

interface PlaceholderProps {
  isActive: boolean;
  type?: string;
}

const Placeholder: React.FC<PlaceholderProps> = ({isActive, type}) => {
  return (
    <Box
      width={type === 'CARD_TYPE_WORKFLOW_CHOICE' ? '110px' : '150px'}
      height={type === 'CARD_TYPE_WORKFLOW_CHOICE' ? '100px' : '145px'}
      border="2px solid rgba(0, 193, 235, 1)"
      borderRadius="4px"
      display={isActive ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      marginBottom="10px"
    >
      <Rocket />
    </Box>
  );
};

export default Placeholder;
