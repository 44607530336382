import React, {useState} from 'react';
import {Box, Typography, Divider} from '@mui/material';
import './History.css';

const rows = [
  {id: 1, action: 'Criar tarefa', responsible: 'Nei Pavaneli', date: '03 SET'},
  {
    id: 2,
    action: 'Criou tarefa',
    responsible: 'Marina Menezes',
    date: '05 SET',
  },
  {id: 3, action: 'Moveu tarefa', responsible: 'Nei Pavaneli', date: '12 SET'},
  {
    id: 4,
    action: 'Moveu tarefa',
    responsible: 'Bruno Anselmo Dias',
    date: '13 SET',
  },
];

const History: React.FC = () => {
  return (
    <Box>
      <Typography fontSize="16px" fontWeight="700" color="#001B37">
        Histórico
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="600"
        color="#001B37"
        marginBottom="37px"
      >
        Veja o registro completo de ações e atualizações
      </Typography>

      <Box display="flex" flexDirection="row" padding="14px">
        <Typography className="history-header-table" sx={{width: '200px'}}>
          Ação
        </Typography>
        <Typography className="history-header-table" sx={{width: '100px'}}>
          Feito por
        </Typography>
        <Typography
          className="history-header-table"
          sx={{width: '100px', marginLeft: '50px'}}
        >
          Quando
        </Typography>
      </Box>
      {rows.map((row, index) => (
        <Box>
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            borderBottom="1px solid rgba(0, 39, 78, 0.20);"
            padding="14px"
            marginRight="20px"
          >
            <Typography
              fontSize="11px"
              fontWeight="700"
              color="#4D5156"
              sx={{width: '200px'}}
            >
              {row.action}
            </Typography>
            <Typography className="history-row-table" sx={{width: '100px'}}>
              {row.responsible}
            </Typography>
            <Typography className="history-row-table" sx={{marginLeft: '50px'}}>
              {row.date}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default History;
