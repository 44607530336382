import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography, Box} from '@mui/material';
import {EnumToType} from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType';
import {WorkflowStepCardValueDto} from '@/models/workflow-step-card-value.dto.ts';

interface WorkflowKanbanCardValueProps {
  cardValue: WorkflowStepCardValueDto;
}

export const WorkflowKanbanCardValue = ({
  cardValue,
}: WorkflowKanbanCardValueProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="5px"
      alignItems="center"
      marginBottom="10px"
    >
      <FontAwesomeIcon
        width="16px"
        style={{color: '#003E7C', marginRight: '8px'}}
        icon={EnumToType(cardValue.type).CardIcon}
      />
      <Box display="flex" flexDirection="column">
        <Typography color="#003E7C" fontSize="12px" fontWeight="500">
          {cardValue.workflowFormInput.label}
        </Typography>
        <Typography fontSize="10px" fontWeight="500">
          {cardValue.value}
        </Typography>
      </Box>
    </Box>
  );
};
