import React, {ReactNode} from 'react';
import {Box, Modal, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';

interface ModalContainerProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{zIndex: '10000000', border: 'none'}}
    >
      <Box
        display="flex"
        position="absolute"
        top={10}
        bottom={10}
        right={10}
        left={10}
        flexDirection="column"
        gap="20px"
        borderRadius="5px"
        padding="30px 70px 30px 70px"
        sx={{
          background: '#ffff',
          outline: 'none',
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          onClick={handleClose}
          style={{
            cursor: 'pointer',
            height: '18px',
            color: '#00274E',
            position: 'absolute',
            top: 30,
            right: 30,
          }}
        />

        {children}
      </Box>
    </Modal>
  );
};

export default ModalContainer;
