import React, {ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import PersonTwo from '@/assets/svg/header/filter/PersonTwo.png';
import PersonThree from '@/assets/svg/header/filter/PersonThree.png';
import PersonOne from '@/assets/svg/header/filter/PersonOne.png';

interface ContainerProps {
  children?: ReactNode;
}

const persons = [
  {id: 1, photo: PersonOne, active: false},
  {id: 2, photo: PersonTwo, active: false},
  {id: 3, photo: PersonThree, active: false},
];

const FilterHeader: React.FC<ContainerProps> = ({children}) => {
  return (
    <Box display="flex" flexDirection="row">
      {persons.map((item) => (
        <Box
          key={item.id}
          sx={{
            borderRadius: '50%',
            borderColor: item.active ? '#4D5156' : '#FFF',
            borderWidth: '1px',
            marginLeft: '-5px',
          }}
          onClick={() => {
            item.active = true;
          }}
        >
          <img src={item.photo} width="32px" />
        </Box>
      ))}
      <Box
        sx={{
          borderRadius: '50%',
          marginLeft: '-5px',
          width: '35px',
          backgroundColor: '#003E7C',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: '#fff',
            fontSize: '14px',
            fontWeight: '500px',
          }}
        >
          {' '}
          +3
        </Typography>
      </Box>
    </Box>
  );
};

export default FilterHeader;
