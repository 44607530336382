import React, {useState} from 'react';
import {Box, Typography, Button, TextField, InputLabel} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import StepConfigurationModal from '@/pages/stepConfigurationModal/StepConfigurationModal';

const rows = [
  {
    id: 1,
    taskName: 'É um lead válido?',
    answer: '',
  },
  {
    id: 2,
    taskName: 'Todos os campos foram preenchidos?',
    answer: '',
  },
  {
    id: 3,
    taskName: 'Foi feito a conferência dos anexos?',
    answer: '',
  },
];

const Tasks: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const handlOpen = () => {
    setOpenModal(true);
    console.log('modal aberto');
  };

  return (
    <>
      <Box display="flex" flexDirection="row" gap="10px" width="100%">
        <Box width="100%" marginRight="20px">
          <Box
            display="flex"
            flexDirection="row"
            gap="27px"
            alignItems="center"
            marginBottom="5px"
          >
            <Typography fontSize="16px" fontWeight="700" color="#001B37">
              Tarefas obrigatórias
            </Typography>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: '#0897CB',
                border: 'none',
                color: '#ffffff',
                fontSize: '12px',
                padding: '5px 8px',
                ':hover': {
                  backgroundColor: '#00C1EB',
                  border: 'none',
                },
              }}
              startIcon={<FontAwesomeIcon icon={faPlus} color="#ffffff" />}
              onClick={handlOpen}
            >
              Nova tarefa obrigatória
            </Button>
          </Box>
          <Typography
            fontSize="12px"
            fontWeight="600"
            color="#001B37"
            marginBottom="27px"
          >
            Todos os arquivos anexados no cartão e também das respostas colhidas
          </Typography>
          <Box display="flex" flexDirection="column" gap="26px">
            {rows.map((row, index) => (
              <Box>
                <InputLabel
                  htmlFor="filled-required"
                  sx={{
                    color: '#00152A',
                    fontSize: '10px',
                    fontWeight: '700',
                  }}
                  required
                >
                  {row.taskName}
                </InputLabel>
                <TextField hiddenLabel variant="outlined" fullWidth />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <StepConfigurationModal isOpen={openModal} onClose={handleCloseModal} />
    </>
  );
};

export default Tasks;
