import React, {useState} from 'react';
import {Box, Typography, Button, Checkbox, TextField} from '@mui/material';
import {faPlus, faSpider} from '@fortawesome/pro-solid-svg-icons';
import {faSpiderWeb} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';

const itens = [
  {
    position: '1 -',
    title: 'Organize por prioridade',
    subtitle:
      'Defina as tarefas obrigatórias com base na importância para que a equipe saiba quais ações são fundamentais para avançar. ',
  },
  {
    position: '2 -',
    title: 'Simplifique:',
    subtitle:
      'Mantenha o número de tarefas obrigatórias o mais enxuto possível. Isso reduz a complexidade e acelera o progresso. ',
  },
  {
    position: '3 -',
    title: 'Valide campos essenciais:',
    subtitle:
      'Certifique-se de que os campos obrigatórios são realmente críticos. Evite sobrecarregar a equipe com etapas desnecessárias. ',
  },
  {
    position: '4 -',
    title: 'Personalização flexível:',
    subtitle:
      'Você pode ajustar as tarefas obrigatórias a qualquer momento conforme o projeto evolui ou novas necessidades surgem. ',
  },
];

const EmptyMandatoryTasks: React.FC = () => {
  const [newAction, setNewAction] = useState(false);
  const [answers, setAnswers] = useState([]);

  return (
    <Box flex="0 0 30%" maxWidth="30%">
      <Box
        display="flex"
        flexDirection="row"
        gap="27px"
        alignItems="center"
        marginBottom="5px"
      >
        <Typography fontSize="16px" fontWeight="700">
          Configurações dos campos
        </Typography>
      </Box>
      <Typography marginBottom="28px">
        Personalize os campos adicionados
      </Typography>
      <Box width="100%" sx={{backgroundColor: '#D6F1FF'}} padding="15px">
        <Typography fontSize="12px" fontWeight="700" marginBottom="13px">
          Dicas úteis
        </Typography>
        {itens.map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            gap="10px"
            marginBottom="13px"
          >
            <Typography fontSize="12px" fontWeight="600" whiteSpace="nowrap">
              {item.position}
            </Typography>
            <Typography>
              <strong>{item.title}</strong> {item.subtitle}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        variant="outlined"
        sx={{
          border: 'none',
          color: '#003E7C',
          fontSize: '13px',
          padding: '5px 8px',
          ':hover': {
            backgroundColor: '#00C1EB',
            border: 'none',
          },
        }}
      >
        Concluir
      </Button>
    </Box>
  );
};

export default EmptyMandatoryTasks;
