import React, {useState} from 'react';
import {Modal, Box, Typography, Divider, Button} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import emptyFormImage from '@/assets/svg/new-form.png';

import {faCalendarDays} from '@fortawesome/pro-solid-svg-icons';
import {
  EmptyMandatoryTasks,
  MandatoryTasks,
  StepConfiguration,
} from '@/sections/index';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {
  ModalContainer,
  MandatoryTasksMenu,
  EmptyForm,
} from '@/components/index';
import {faSquareCheck, faGear} from '@fortawesome/pro-solid-svg-icons';

interface StepConfigurationModal {
  isOpen: boolean;
  onClose: () => void;
}

const StepConfigurationModal: React.FC<StepConfigurationModal> = ({
  isOpen,
  onClose,
}) => {
  const [activeSection, setActiveSection] = useState('Tarefas');

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={handleClose}>
      {/* Header do modal */}

      <Box display="flex" flexDirection="column" gap="16px">
        {/* Header principal */}
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            <EmojiIcon emoji={Emoji.Warning} width="26px" />
            <Box>
              <Typography fontSize="10px" fontWeight="600" color="#0059B2">
                Você está na etapa:
              </Typography>
              <Typography fontSize="14px" fontWeight="700" color="#003E7C">
                Em andamento
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            color="#B7E6FF"
            style={{
              height: '13px',
              alignSelf: 'center',
            }}
          />
          <Box display="flex" gap="10px">
            <Button
              variant="outlined"
              onClick={() => setActiveSection('Tarefas')}
              sx={{
                backgroundColor:
                  activeSection === 'Tarefas' ? '#D6F1FF' : 'transparent',
                border: 'none',
                color: '#003E7C',
                fontSize: '13px',
                padding: '5px 8px',
                ':hover': {
                  backgroundColor: '#D6F1FF',
                  border: 'none',
                },
              }}
              startIcon={
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  color="#003E7C"
                  width="12px"
                />
              }
            >
              Tarefas
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              color="#B7E6FF"
              style={{
                height: '13px',
                alignSelf: 'center',
              }}
            />
            <Button
              variant="outlined"
              onClick={() => setActiveSection('Configurar etapa')}
              sx={{
                backgroundColor:
                  activeSection === 'Configurar etapa'
                    ? '#D6F1FF'
                    : 'transparent',
                border: 'none',
                color: '#003E7C',
                fontSize: '13px',
                padding: '5px 8px',
                ':hover': {
                  backgroundColor: '#D6F1FF',
                  border: 'none',
                },
              }}
              startIcon={
                <FontAwesomeIcon icon={faGear} color="#003E7C" width="12px" />
              }
            >
              Configurar etapa
            </Button>
          </Box>
        </Box>
      </Box>

      {activeSection === 'Tarefas' && <MandatoryTasks />}
      {activeSection === 'Configurar etapa' && <StepConfiguration />}
    </ModalContainer>
  );
};

export default StepConfigurationModal;
