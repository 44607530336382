import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fab, Grid, Button} from '@mui/material';
import './index.css';
import SvgMais from '@/assets/svg/mais.svg?react';
import {ToastError} from '@/components/shared';
import {getAllWorkflowSteps} from '@/services/workflow-step/workflow-step.service.ts';
import {getWorkflowById} from '@/services/workflow/workflow.service.ts';
import {useParams} from 'react-router';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {WorkflowKanbanCard} from '@/pages/workflow/kanban/components/workflowKanbanCard';
import {WorkflowDto} from '@/models/workflow.dto.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import update from 'immutability-helper';
import {WorkflowStepCardDto} from '@/models/workflow-step-card.dto.ts';
import KanbanColumn from '@/pages/workflow/kanban/components/kanbanColumn';
import {KanbanItem} from '@/pages/workflow/kanban/components/kanbanItem';
import NewCardForm from '@/pages/workflow/kanban/components/newCardForm';
import KanbanModal from '../components/kanbanModal';
import OpenCardModal from '@/pages/openCardModal/OpenCardModal';

const WorkflowKanban: React.FC = () => {
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workflowSteps, setWorkflowSteps] = useState<Array<WorkflowStepDto>>(
    [],
  );
  const [selectedCard, setSelectedCard] = useState<WorkflowStepCardDto | null>(
    null,
  );
  const [cards, setCards] = useState<Array<WorkflowStepCardDto>>([]);
  const [isCardClicked, setIsCardClicked] = useState(false);
  const [workflow, setWorkflow] = useState<Partial<WorkflowDto>>({
    icon: '',
    title: '',
  });

  const [isOpenCardForm, setIsOpenCardForm] = useState(false);
  const handleCardClick = (card: WorkflowStepCardDto) => {
    setSelectedCard(card);
    setIsCardClicked(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
    setIsCardClicked(false);
  };

  const handleSubmitModal = async () => {
    await fetchWorkflowStepsSync();
    setOpenModal(false);
  };

  useEffect(() => {
    fetchWorkflowStepsSync();
  }, []);

  async function fetchWorkflowStepsSync() {
    setLoading(true);
    const {id} = params;

    try {
      const result = await getAllWorkflowSteps(id);
      const resultWorkflow = await getWorkflowById(id);

      const data = result.data;
      data.sort((a, b) => a.order - b.order);

      setWorkflowSteps(data);
      setWorkflow(resultWorkflow.data);
      setCards(data.flatMap((s) => s.cards));
    } catch (e) {
      ToastError({
        title: 'Erro!',
        text: 'Erro ao listar fluxos de trabalho. Contate o administrador',
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const changeCardColumn = useCallback(
    (item, step_id) => {
      console.log(item.id, step_id, 'asdas');
      let card = cards.find((c) => c.id === item.id);
      const cardIndex = cards.indexOf(card);
      card = {...card, step_id};
      let newCards = update(cards, {
        [cardIndex]: {$set: card},
      });
      setCards(newCards);
    },
    [cards],
  );

  return (
    <Box
      sx={{
        marginTop: '20px',
        maxHeight: '100%',
      }}
    >
      <Grid
        container
        className={'workflow-board'}
        spacing={4}
        sx={{
          gridAutoColumns: '280px',
          display: 'grid',
          gridAutoFlow: 'column',
        }}
      >
        {workflowSteps.map((step) => (
          <Grid item key={step.id} sx={{}}>
            <KanbanColumn
              step={step}
              cards={cards}
              changeCardColumn={changeCardColumn}
            >
              {cards
                .filter((c) => c.step_id === step.id)
                .map((card) => (
                  <KanbanItem key={card.id} id={card.id}>
                    <WorkflowKanbanCard
                      card={card}
                      onClick={() => handleCardClick(card)}
                    />
                  </KanbanItem>
                ))}
            </KanbanColumn>
          </Grid>
        ))}
        <Grid item>
          <Button
            variant="text"
            startIcon={<SvgMais fill="#0897CB" width={'13px'} />}
            onClick={handleOpenModal}
            sx={{
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Criar etapa
          </Button>
        </Grid>
      </Grid>

      <Box sx={{position: 'absolute', bottom: '50px'}}>
        <Fab
          size="medium"
          variant="extended"
          onClick={() => setIsOpenCardForm(true)}
          sx={{
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          <FontAwesomeIcon style={{marginRight: 12}} icon={faPlus} />
          Criar cartão
        </Fab>
      </Box>

      <KanbanModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
      <NewCardForm
        isOpen={isOpenCardForm}
        onClose={() => setIsOpenCardForm(false)}
        workflow={workflow}
      />
      <OpenCardModal
        isOpen={isCardClicked}
        onClose={handleCloseModal}
        card={selectedCard}
      />
    </Box>
  );
};

export default WorkflowKanban;
